<template>
  <el-dialog
      title="关闭订单"
      :visible.sync="closeOrderDialogVisible"
      width="400px"
      :closeOnClickModal="false"
      :closeOnPressEscape="false"
      :before-close="handleClose">
    <el-form label-position="left" class="common-form" label-width="100px" :model="form" :rules="rules" ref="form">
      <el-form-item label="关闭原因：" prop="orderCause">
        <el-select class="small-input" v-model="form.orderCause" placeholder="请选择关闭原因">
          <el-option
              v-for="item in orderCauseList"
              :key="item.content"
              :label="item.content"
              :value="item.content">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="not_required" label="备注：" prop="remark">
        <el-input type="textarea" :rows="5" class="small-input" v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="close()">取 消</el-button>
    <el-button size="small" type="primary" :loading="loading" @click="submit">关闭订单</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "CloseOrder",
  props:{
    closeOrderDialogVisible:{
      type:Boolean,
      default:false
    },
    orderId:{
      type:String,
      default: ''
    },
  },
  watch:{
    closeOrderDialogVisible(val){
      if(!val){
        this.form.orderCause = '';
        this.form.remark = '';
        this.$refs.form.resetFields();
      }
    }
  },
  data(){
    return {
      loading:false,
      form:{
        orderCause:'',
        remark:''
      },
      rules: {
        orderCause:[
          {required: true, message: '请选择关闭原因', trigger: 'change'},
        ]
      },
      orderCauseList:[]
    }
  },
  methods:{
    handleClose(){
      this.close();
    },
    close(isRe){
      this.$emit("close",false,isRe)
    },
    submit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let formData = {
            id:this.orderId,
            orderCause:this.form.orderCause,
            remark:this.form.remark
          }
          this.$axios.post("/sys-api/order-api/closeOrder",formData,(res) => {
            this.loading = false;
            if(res.code === '100'){
              this.$message.success('关闭订单成功');
              this.close(true);
            }
          })
        }
      })
    },
    getOrderCauseList(){
      this.$axios.get("/sys-api/order-api/getOrderCause",{},(res) => {
        if(res.code === '100'){
          this.orderCauseList = res.data;
        }
      })
    }
  },
  mounted() {
    this.getOrderCauseList();
  }
}
</script>

<style scoped lang="scss">

</style>
