<template>
  <el-dialog
      title="添加备注"
      :visible.sync="addRemarkDialogVisible"
      width="400px"
      :closeOnClickModal="false"
      :closeOnPressEscape="false"
      :before-close="handleClose">
    <el-form label-position="right" class="common-form" label-width="100px" :model="form" :rules="rules" ref="form">
      <el-form-item label="备注类型：" prop="markType">
        <el-select clearable class="small-input" v-model="form.markType" @change="changeMarkType" placeholder="备注类型">
          <el-option
                  v-for="item in markTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="this.isShow" label="退款类型：" prop="refundType">
        <el-select clearable class="small-input" v-model="form.refundType" placeholder="退款类型">
          <el-option
                  v-for="item in refundTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="this.isShow" label="退款金额：" prop="amount">
        <el-input class="small-input" type="number" placeholder="请输入退款金额" v-model="form.amount">
        </el-input>元
      </el-form-item>
      <el-form-item label="备注：" prop="content">
        <el-input
                class="small-input"
            type="textarea"
            :rows="5"
            placeholder="请输入备注"
            v-model="form.content">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="close()">取 消</el-button>
    <el-button size="small" type="primary" :loading="loading" @click="submit">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "AddRemark",
  props:{
    addRemarkDialogVisible:{
      type:Boolean,
      default:false
    },
    orderId:{
      type:String,
      default: ''
    },
  },
  watch:{
    addRemarkDialogVisible(val){
      if(!val){
        this.form.content = null;
        this.isShow=false;
        this.form.refundType=null;
        this.form.amount=null;
        this.form.markType=null;
        this.$refs.form.resetFields();
      }
    }
  },
  data(){
    return {
      loading:false,
      isShow:false,
      form:{
        orderId:this.orderId,
        content:null,
        markType:null,
        refundType:null,
        amount:null
      },
      markTypeList:[],
      refundTypeList:[],
      rules:{
        amount:[
          {required: true, message: '请输入金额', trigger: 'blur'},
        ],
        refundType:[
          {required: true, message: '请选择退款金额', trigger: 'blur'},
        ],
        markType:[
          {required: true, message: '请选择备注类型', trigger: 'blur'},
        ]
      }
    }
  },
  methods:{
    handleClose(){
      this.close();
    },
    close(isRe){
      this.$emit("close",false,isRe)
    },
    changeMarkType(){
      if (this.form.markType == 1){
        this.isShow=true;
      } else {
        this.isShow=false;
        this.form.refundType=null;
        this.form.amount=null;
      }
    },
    submit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios.post("/sys-api/order-api/addRecord",this.form,(res) => {
            this.loading = false;
            if(res.code === '100'){
              this.$message.success("添加备注成功");
              this.close(true);
            }
          })
        }
      })
    }
  },
  mounted() {
    const testJson = require('../../../public/common_cofig.json');
    const {markTypeList,refundTypeList} = testJson;
    this.markTypeList = markTypeList;
    this.refundTypeList = refundTypeList;
  }
}
</script>

<style scoped lang="scss">
  ::v-deep input.el-input__inner::-webkit-outer-spin-button,
  ::v-deep input.el-input__inner::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  ::v-deep input.el-input__inner[type="number"] {
    -moz-appearance: textfield !important;
  }
  .small-input {
    width: 90%;
  }
</style>
