<template>
  <el-dialog
      :title="type === 0 ? '退押金' : type === 1 ? '退租金' : type === 2 ? '退日间金额' : type === 3 ? '退夜间金额' : ''"
      :visible.sync="startRefundDialogVisible"
      width="500px"
      :closeOnClickModal="false"
      :closeOnPressEscape="false"
      :before-close="handleClose">
    <el-form label-position="left" class="common-form" label-width="100px" :model="form" :rules="rules" ref="form">
      <el-form-item label="退款金额：" prop="refundAmount">
        <el-input class="small-input" type="number" placeholder="请输入退款金额" v-model="form.refundAmount">
        </el-input>
        （最多<span style="color: #2279f4">{{actualAmount}}</span>元）
      </el-form-item>
      <!--      <el-form-item class="not_required" label="退款原因：" prop="refundCause">-->
      <!--        <el-input-->
      <!--            type="textarea"-->
      <!--            :rows="5"-->
      <!--            placeholder="请输入退款原因"-->
      <!--            v-model="form.refundCause">-->
      <!--        </el-input>-->
      <!--      </el-form-item>-->
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="close()">取 消</el-button>
    <el-button size="small" type="primary" :disabled="actualAmount === 0" :loading="loading" @click="submit">发起退款</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "StartRefund",
  props: {
    startRefundDialogVisible: {
      type: Boolean,
      default: false
    },
    paymentNo: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: -1
    }
  },
  watch: {
    startRefundDialogVisible(val) {
      if (!val) {
        this.form.refundCause = '';
        this.$refs.form.resetFields();
      } else {
        let formData = {
          type:this.type,
          paymentNo:this.paymentNo
        }
        this.$axios.post("/sys-api/order-api/queryRefund",formData,(res) => {
          if(res.code === '100'){
            this.actualAmount = res.data.actualAmount;
            if(this.actualAmount === 0) this.$message.info("可退款金额为0！")
          }
        })
      }
    }
  },
  data() {
    const validateRefundAmount = (rule, value, callback) => {
      if (/^[0-9]+.?[0-9]*$/.test(value)) {
        if(Number(value) > this.actualAmount) callback(new Error("退款金额大于实际金额"));
        else callback();
      } else {
        callback(new Error("退款金额必须为正数"))
      }
    };
    return {
      loading: false,
      form: {
        refundAmount: '',
      },
      rules: {
        refundAmount: [
          {required: true, message: '请输入退款金额', trigger: 'blur'},
          {validator: validateRefundAmount, trigger: 'blur'}
        ],
      },
      actualAmount:0
    }
  },
  methods: {
    handleClose() {
      this.close();
    },
    close(isRe) {
      this.$emit("close", false, isRe)
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let formData = {
            type: this.type,
            paymentNo: this.paymentNo,
            refund: this.form.refundAmount,
          }
          this.$axios.post("/sys-api/order-api/verifyRefund", formData, (res) => {
            this.loading = false;
            if (res.code === '100') {
              this.$message.success(res.msg);
              this.close(true);
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep input.el-input__inner::-webkit-outer-spin-button,
::v-deep input.el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input.el-input__inner[type="number"] {
  -moz-appearance: textfield !important;
}

.small-input {
  width: 60%;
}
</style>
