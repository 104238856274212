import { render, staticRenderFns } from "./AddRemark.vue?vue&type=template&id=79ce4741&scoped=true&"
import script from "./AddRemark.vue?vue&type=script&lang=js&"
export * from "./AddRemark.vue?vue&type=script&lang=js&"
import style0 from "./AddRemark.vue?vue&type=style&index=0&id=79ce4741&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ce4741",
  null
  
)

export default component.exports